body{
    font-family: montserrat;
}
p{
    font-family: montserrat;
}
.fc-toolbar-title {
    text-transform: capitalize;
  }

  .fc-button {
    color: white;
  }

  .tox-promotion {
    display: none;
}

.tox-statusbar__branding{
    display: none;

}

.tox:not(.tox-tinymce-inline) .tox-editor-header:not(.tox-editor-dock-transition) {
  transition: box-shadow .5s;
  background: #b6d1e8;
}

.tox .tox-promotion+.tox-menubar{
  background-color: #b6d1e8;
}

.tox .tox-toolbar-overlord .tox-toolbar:not(.tox-toolbar--scrolling):first-child, .tox .tox-toolbar-overlord .tox-toolbar__primary{
  background-color: #d6e0e9;
  margin-top: -2px; 
}

.tox .tox-statusbar {
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e3e3e3;
  color: rgba(34,47,62,.7);
  display: flex;
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  overflow: hidden;
  padding: 0 8px;
  position: relative;
  text-transform: none;
  background:#b6d1e8 ;
}

/***Style for loader */
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #3b7d82;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  border-color: #FF3D00;
  animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

/* Effet de transition pour les cartes */
.paiement{
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.paiement:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(85, 72, 72);

}

/* Animation d'apparition pour les cartes */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.paiement {
  animation: fadeInUp 0.5s ease-in-out;
}


.paiement {
  border-radius: 8px;
  min-height: 90px; /* Assure une hauteur minimale uniforme */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem;
}

.bg-light-blue-paiement {
  background-color: #eaf4fc; /* Bleu pastel */
}

.bg-light-green-paiement {
  background-color: #e6f7e6; /* Vert pastel */
}

.bg-light-yellow-paiement {
  background-color: #fffbea; /* Jaune pastel */
}

.bg-light-cyan-paiement {
  background-color: #eafafc; /* Cyan pastel */
}

.bg-light-red-paiement {
  background-color: #fdeaea; /* Rouge pastel */
}

.card-title-paiement {
  font-size: 1.5rem; /* Taille adaptée pour les montants */
  font-weight: bold;
  color: inherit; /* Assure une bonne correspondance avec le thème */
}

.card-text-paiement {
  font-size: 0.9rem;
  color: #161616;
}

.card-body-paiement {
  text-align: center;
}

@keyframes scale-in {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}


.dialog-content {
  display: flex;
  gap: 20px;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.payment-column {
  flex: 1;
  padding: 10px;
}

.payment-header {
  text-align: center;
  background: #eaf7f1;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 1rem;
  font-weight: 600;
}

.payment-options {
  display: flex;
  /*flex-wrap: wrap;*/
  gap: 10px;
}

.payment-options-mobile {
  gap: 10px;
}

.payment-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background: #f8f9fa;
  cursor: pointer;
  transition: all 0.3s;
}

.payment-label {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-left: 10px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
}

.action-buttons-mobile {
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
}

.action-buttons-mobile button{
  margin-bottom: 10px;
}
