.info-card {
    border: none;
    border-radius: 0.75rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
}

.info-card:hover {
    transform: translateY(-0.5rem);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-icon {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.card-title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-value {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

@media (max-width: 768px) {
    .card-title {
        font-size: 0.875rem;
    }

    .card-value {
        font-size: 1rem;
    }
}
