.voir-tout .container {
    background-color: #f8f9fa; /* Fond blanc cassé */
    border-radius: 8px;
    padding: 20px;
  }
  
  .voir-tout h2 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #002060; /* Bleu foncé officiel */
    margin-bottom: 20px;
  }
  
  .voir-tout .section-title {
    font-size: 1.2rem;
    color: #002060;
    border-bottom: 2px solid #edd712; /* Ligne décorative */
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  
  .voir-tout .menu-link {
    display: block;
    text-decoration: none;
    color: #0056b3; /* Bleu officiel */
    font-size: 0.95rem;
    margin-bottom: 8px;
    transition: color 0.2s, text-shadow 0.2s;
  }
  
  .voir-tout .menu-link:hover {
    color: #002060; /* Accentuation en bleu foncé */
    text-shadow: 0 0 4px #d9e2f3;
  }
  